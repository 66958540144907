import React from "react";
import { Location } from "@reach/router";

import Headline from "global/Headline";
import zparse from "helpers/zparse";
import DataProvider from "providers/data";

const ContentPage = (props) => {
    let page = { ...props };

    if (
        (props.providerResponse.data.length > 0 || !!page !== false) &&
        props.providerStatusText === "LOADED"
    ) {
        page = { ...page, ...props.providerResponse.data[0] }; // merge custom template props into latest data
        page = { ...page, ...props.forceOptions };
    }

    return (
        <>
			<Headline
				{...{
					body: page.headlineBody,
					title: page.headlineTitle,
					image: page.headlineImage,
					columnSize: page.columnSize ? page.columnSize : 9,
					isPageHeader: true,
					type: page.headlineType,
					bgColor: page.headlineBgColor,
					color: page.headlineColor,
				}}
			/>
            {page.hasOwnProperty("pageNav") && page.pageNav.length > 0 && (
                <nav id="page-nav">
                    <div className="grid-container">
                        <div className="grid-padding-x grid-x">
                            <div className="cell">
                                <ul className="menu">
                                    {page.pageNav.map(nav => (
                                        <li key={`page-nav-${nav.id}`}><a href={`#${nav.id}`}>{nav.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            )}
			{props.preContent}
            {!props.hideContent && (
                <section
                    id="content-page"
                    className={`content-page-${props.location.pathname.replace(
                        /\//gm,
                        ""
                    )}`}
                >
                    <div className="grid-container">
                        <div className="grid-margin-x grid-x align-center">
                            <div
                                className={`small-12 medium-${props.gridSize ? props.gridSize : 11
                                    } cell`}
                            >
                                {zparse(page.content ? page.content.main : "")}
                            </div>
                        </div>
                    </div>
                    {props.children}
                </section>
            )}
			{props.postContent}
        </>
    );
};

const Content = (props) => (
    <Location>
        {(locationProps) => (
            <DataProvider
                liveRefresh={true}
                type="ContentPage"
                apiParams={{ uri: locationProps.location.pathname, _join: true }}
            >
                <ContentPage {...props} location={locationProps.location} />
            </DataProvider>
        )}
    </Location>
);
export default Content;
