import React from "react";

import zparse from "helpers/zparse";

const Headline = props => {
	let { image, title, body, columnSize = 4, bgColor, color, type, isPageHeader } = props;
	if (isPageHeader === undefined) isPageHeader = false;
	if (type === "bg-color") {
		image = "";
	} else if (type === "no-bg") {
		image = "";
		bgColor = "";
	} else {
		bgColor = "";
	}
	if (!title) return null;
	const isHtml = /<\/?[a-z][\s\S]*>/i;
	return (
		<div className={`headline ${(!image && !bgColor) ? "no-shadow" : "" } ${isPageHeader ? `page-header` : ""}`} style={{ backgroundImage: `url('${image}')`, backgroundColor: bgColor }}>
			<div className="grid-container">
				<div className={`grid-x grid-padding-x ${isPageHeader ? "align-middle small-align-center" : ""}`}>
					<div className={`cell small-11 small-order-1 medium-order-2 medium-${columnSize} content-block`}>
						{isPageHeader ? <h1 style={{ color }}>{zparse(title)}</h1> : <h2 style={{ color }}>{zparse(title)}</h2>}
						{body && (isHtml.test(body) ? zparse(body) : <p style={{ color }}>{body}</p>)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Headline;