import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';
import Layout from 'templates/Layout';
import zparse from 'helpers/zparse';
import ReadTime from 'components/lib/ReadTime'
import Link from 'components/Link'
import Content from 'templates/Content';

import useLiveData from 'hooks/useLiveData';


const PressReleaseGet = (props) => {
	const { edges: nodes } = props.data.allPressRelease;
	const releases = nodes.map(({ node }) => node);
	let release = releases[0];

	const { statusText: providerStatusText, status: providerStatus, response: providerResponse, visibilityChange } = useLiveData({
		type: 'PressRelease',
		apiParams: {
			uri: props.location.pathname,
		},
		staticData: props.data,
	});

	if (providerStatus < 2) return null; // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
	if ((providerResponse.data.length > 0 || !!release !== false) && providerStatusText === 'LOADED') {
		release = providerResponse.data[0];
	}
	if (providerResponse && visibilityChange) release = providerResponse.data[0];
	if (!!release === false && providerStatus >= 2) return <NotFound />;

	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const d = new Date(Date.parse(release.showDate));
	const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
	return (
		<>
			<Layout>
				{release.photo && (
					<div className="press-content-wrapper">
						<Content headlineImage={release.photo} headlineTitle={release.headline} />
					</div>
				)}
				<section id="press">
					<div className="grid-container">
						<div className="grid-x grid-margin-x align-center">
							<div className="small-12 medium-8 cell">
								<small><Link to={`/press/`}>&laquo; Back to Press Landing</Link></small>
								{!release.photo && <h1>{release.headline}</h1>}
								<div className={`byline`}>
									<ReadTime className={`readTime`} data={release.body} prefix='' suffix='Min Read' />
									<div className={`separator`}>|</div>
									<div className={`date`}>{dateString}</div>
								</div>
								{zparse(release.body || '')}
							</div>
						</div>
					</div>
				</section>
			</Layout>
		</>
	)
}

export default PressReleaseGet;

export { Head } from 'components/templates/Head';

export const query = graphql`
query ($permalink: String) {
	allPressRelease(
	  filter: {permalink: {eq: $permalink}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  id
		  headline
		  body
		  photo
		}
	  }
	}
  }
`