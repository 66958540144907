import React, {Component} from 'react'
import readTimeEstimate from 'read-time-estimate'

class ReadTime extends Component {
	render() {
		const className = this.props.className||'';
		const { humanizedDuration } = readTimeEstimate(this.props.data);
		// var duration = Math.ceil(time.duration);
		// var readString = duration;
		// if(this.props.prefix) {
		// 	readString = this.props.prefix + ' ' + readString;
		// }
		// if(this.props.suffix) {
		// 	readString = readString + ' ' + this.props.suffix;
		// }
		return (
			<div className={className}>{humanizedDuration}</div>
		)
	}
}

export default ReadTime;